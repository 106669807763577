<template>

    <!-- form -->
    <validation-observer
    ref="form"
    #default="{invalid}"
    >
      <b-form
      class="auth-register-form"
      @submit.prevent="setCompany"
      >
        <b-row>
          <b-col xs="12" md="12" sm="12" lg="6" xl="6">
            <b-form-group
            label="Teléfono"
            >
              <validation-provider
                #default="{ errors }"
                name="Celular"
                rules="required"
              >
                <VuePhoneNumberInput
                        @update="countryPhone"
                        v-model="formData.telephone"
                        :preferred-countries="['MX', 'AR', 'BR', 'CO', 'CR', 'CL', 'EC', 'PE', 'UY', 'US']"
                        :show-code-on-list="true"
                        :translations="{
                          countrySelectorLabel: 'Código de país',
                          countrySelectorError: 'Seleccione país',
                          phoneNumberLabel: 'Celular con WhatsApp o Telegram'}"
                        default-country-code="MX"
                        :required="true"
                        :no-example="true"
                        :no-validator-state="true"
                        :error="errorPhone" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="Celular"
              >
                <b-form-input ref="hidden_phone" v-model="formated_phone" style="height:0;width:0;overflow:hidden;padding: 0;border: 0;"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col xs="12" md="12" sm="12" lg="6" xl="6">
            <b-form-group
              label="Cuéntanos sobre tu contenido">
              <b-form-textarea
                id="textarea"
                v-model="formData.question_text"
                placeholder="Escribir mensaje..."
                rows="3"
                :required="true"
                max-rows="4"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col>
            <hr>
            <br>
            <b-button
                    variant="primary"
                    block
                    :disabled="clicked || invalid || errorPhone"
                    type="submit"
                  >
                    Guardar Información
                  </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

</template>

<script>
import {
  BFormTextarea, BFormGroup, BRow, BCol, BButton, BForm, BFormInput
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, length } from '@validations'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import service from '@/services/others'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormTextarea,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
    BFormInput
  },
  created () {
    localize('es')
    const self = this
    setTimeout(function() {
      self.$refs.hidden_phone.focus()
    }, 500)
  },
  data () {
    return {
      errorPhone: false,
      clicked: false,
      formData: {
        telephone: '',
        country: '',
        question_text: '',
      },
      required,
      length,
      formated_phone: ''
    }
  },
  methods: {
    setCompany () {
      this.clicked = true;
      this.$refs.form.validate()
        .then(valid => {
          if (valid) {
            service.formCreator(this.formData)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Cambios Guardados',
                    icon: 'AlertIcon',
                    variant: 'success',
                    text: 'Se ha registardo con exito.'
                  }
                })
                this.$emit('next')
              })
              .catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'error al guardar',
                    icon: 'AlertIcon',
                    variant: 'danger',
                    text: 'Ocurrio un error al guardar.'
                  }
                })
              })
          } else this.clicked = false;
        })
    },
    formatNumber(number) {
      const string = number.replace(/ /g,'')
      return `${string.substring(0, 3)} ${string.substring(3, 6)} ${string.substring(6, string.length)}`;
    },
    countryPhone (data) {
      this.formData.country = data.countryCode
      if (data.phoneNumber) {
        this.formData.telephone = this.formated_phone = this.formatNumber(data.phoneNumber)
      } else {
        this.formData.telephone = this.formated_phone = ''
      }
      this.validPhone()
    },
    validPhone () {
      this.errorPhone = this.formated_phone.length > 12 || this.formated_phone.length < 11
    }
  }
}
</script>

<style>
  .app-content {
    padding-top: calc(10rem) !important;
  }
</style>

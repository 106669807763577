<template>

    <!-- form -->
    <validation-observer
    ref="form"
    #default="{invalid}"
    >
      <b-form
      class="auth-register-form"
      @submit.prevent="setCompany"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
            label="Nombre de la marca"
            >
              <validation-provider
                #default="{ errors }"
                name="Nombre"
                rules="required"
              >
                <b-form-input
                  v-model="formData.name"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Nombre de marca"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
            label="Tamaño de la empresa"
            >
              <validation-provider
                #default="{ errors }"
                name="Tam. Empresa"
                rules="required"
              >
                <b-form-select
                  v-model="formData.employees_number"
                  :options="options"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Posición"
            >
              <validation-provider
                #default="{ errors }"
                name="posicion"
                vid="posicion"
                rules="required"
                tag="div"
              >
                <b-form-select
                  id="position"
                  v-model="formData.position"
                  name="position"
                  :class="{'is-invalid': !!errors.length}"
                  :state="errors.length > 0 ? false:null"
                  :options="positions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
            label="Teléfono"
            >
              <validation-provider
                #default="{ errors }"
                name="Celular"
                rules="required"
              >
                <VuePhoneNumberInput
                        @update="countryPhone"
                        v-model="formData.telephone"
                        :preferred-countries="['MX', 'AR', 'BR', 'CO', 'CR', 'CL', 'EC', 'PE', 'UY', 'US']"
                        :show-code-on-list="true"
                        :translations="{
                          countrySelectorLabel: 'Código de país',
                          countrySelectorError: 'Seleccione país',
                          phoneNumberLabel: 'Teléfono'}"
                        default-country-code="MX"
                        :required="true"
                        :no-example="true"
                        :no-validator-state="true"
                        :error="errorPhone" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Celular"
              >
                <b-form-input ref="hidden_phone" v-model="formated_phone" style="height:0;width:0;overflow:hidden;padding: 0;border: 0;"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <hr>
            <b-button
                    :disabled="clicked || invalid || errorPhone"
                    variant="primary"
                    block
                    type="submit"
                  >
                    Registrar Compañia
                  </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

</template>

<script>
import {
  BFormInput, BFormGroup, BRow, BCol, BFormSelect, BButton, BForm
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, length } from '@validations'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import service from '@/services/others'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import utils from "@/libs/utils";
import abilities from '@/libs/acl/abilities'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormSelect,
    BButton,
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput
  },
  created () {
    this.setLoc()
    const self = this
    setTimeout(function() {
      self.$refs.hidden_phone.focus()
    }, 500)
  },
  data () {
    return {
      // ? Default locale for VeeValidate is 'en'
      locale: 'es',
      clicked: false,
      formData: {
        name: '',
        employees_number: 1,
        position: '',
        telephone: '',
        country: ''
      },
      errorPhone: false,
      required,
      length,
      options: [
        { value: 1, text: '1 empleado' },
        { value: 10, text: '2-10 empleados' },
        { value: 50, text: '11-50 empleados' },
        { value: 200, text: '51-200 empleados' },
        { value: 500, text: '201-500 empleados' },
        { value: 1000, text: '501-1000 empleados' },
        { value: 1200, text: '1001+ empleados' }
      ],
      positions: [
        {value: '', text: 'Selecciona posición'},
        {value: 'CEO', text: 'CEO'},
        {value: 'Gerente Financiero', text: 'Gerente Financiero'},
        {value: 'Gerente Comercial', text: 'Gerente Comercial'},
        {value: 'Gerente de Servicios Administrativos', text: 'Gerente de Servicios Administrativos'},
        {value: 'Gerente de Marketing', text: 'Gerente de Marketing'},
        {value: 'Gerente de Recursos Humanos', text: 'Gerente de Recursos Humanos'},
        {value: 'Gerente de TI', text: 'Gerente de TI'},
        {value: 'Gerente de Operaciones', text: 'Gerente de Operaciones'},
        {value: 'Gerente de Comunicaciones', text: 'Gerente de Comunicaciones'},
        {value: 'Gerente de Logística', text: 'Gerente de Logística'},
        {value: 'Otra', text: 'Otra'}
      ],
      formated_phone: ''
    }
  },
  methods: {
    setLoc () {
      // switch the locale.
      this.locale = this.locale === 'en' ? 'es' : 'es'
      localize(this.locale)

      // re-validate to re-generate the messages.
    },
    setCompany () {
      this.clicked = true;
      this.$refs.form.validate()
        .then(valid => {
          if (valid) {
            service.createCompany(this.formData).then(response => {
              const readAbility = abilities.getAbilitie(response.user)
              utils.setuserData(response, readAbility);
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Cambios Guardados',
                  icon: 'AlertIcon',
                  variant: 'success',
                  text: 'Se ha registardo con exito.'
                }
              })
              this.$emit('next')
            })
              .catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'error al guardar',
                    icon: 'AlertIcon',
                    variant: 'danger',
                    text: 'Has iniciado sesión exitosamente.'
                  }
                })
              })
          } else this.clicked = false;
        })
    },
    formatNumber(number) {
      const string = number.replace(/ /g,'')
      return `${string.substring(0, 3)} ${string.substring(3, 6)} ${string.substring(6, string.length)}`;
    },
    countryPhone (data) {
      this.formData.country = data.countryCode
      if (data.phoneNumber) {
        this.formData.telephone = this.formated_phone = this.formatNumber(data.phoneNumber)
      } else {
        this.formData.telephone = this.formated_phone = ''
      }
      this.validPhone()
    },

    validPhone () {
      this.errorPhone = this.formated_phone.length > 12 || this.formated_phone.length < 11
    }
  }
}
</script>

<style>
  .app-content {
    padding-top: calc(10rem) !important;
  }
</style>

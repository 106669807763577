var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form",on:{"submit":function($event){$event.preventDefault();return _vm.setCompany.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nombre de la marca"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false:null,"placeholder":"Nombre de marca"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tamaño de la empresa"}},[_c('validation-provider',{attrs:{"name":"Tam. Empresa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options,"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.employees_number),callback:function ($$v) {_vm.$set(_vm.formData, "employees_number", $$v)},expression:"formData.employees_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Posición"}},[_c('validation-provider',{attrs:{"name":"posicion","vid":"posicion","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{class:{'is-invalid': !!errors.length},attrs:{"id":"position","name":"position","state":errors.length > 0 ? false:null,"options":_vm.positions},model:{value:(_vm.formData.position),callback:function ($$v) {_vm.$set(_vm.formData, "position", $$v)},expression:"formData.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Teléfono"}},[_c('validation-provider',{attrs:{"name":"Celular","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"preferred-countries":['MX', 'AR', 'BR', 'CO', 'CR', 'CL', 'EC', 'PE', 'UY', 'US'],"show-code-on-list":true,"translations":{
                      countrySelectorLabel: 'Código de país',
                      countrySelectorError: 'Seleccione país',
                      phoneNumberLabel: 'Teléfono'},"default-country-code":"MX","required":true,"no-example":true,"no-validator-state":true,"error":_vm.errorPhone},on:{"update":_vm.countryPhone},model:{value:(_vm.formData.telephone),callback:function ($$v) {_vm.$set(_vm.formData, "telephone", $$v)},expression:"formData.telephone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Celular"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{ref:"hidden_phone",staticStyle:{"height":"0","width":"0","overflow":"hidden","padding":"0","border":"0"},model:{value:(_vm.formated_phone),callback:function ($$v) {_vm.formated_phone=$$v},expression:"formated_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('hr'),_c('b-button',{attrs:{"disabled":_vm.clicked || invalid || _vm.errorPhone,"variant":"primary","block":"","type":"submit"}},[_vm._v(" Registrar Compañia ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form",on:{"submit":function($event){$event.preventDefault();return _vm.setCompany.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"xs":"12","md":"12","sm":"12","lg":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Teléfono"}},[_c('validation-provider',{attrs:{"name":"Celular","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"preferred-countries":['MX', 'AR', 'BR', 'CO', 'CR', 'CL', 'EC', 'PE', 'UY', 'US'],"show-code-on-list":true,"translations":{
                      countrySelectorLabel: 'Código de país',
                      countrySelectorError: 'Seleccione país',
                      phoneNumberLabel: 'Celular con WhatsApp o Telegram'},"default-country-code":"MX","required":true,"no-example":true,"no-validator-state":true,"error":_vm.errorPhone},on:{"update":_vm.countryPhone},model:{value:(_vm.formData.telephone),callback:function ($$v) {_vm.$set(_vm.formData, "telephone", $$v)},expression:"formData.telephone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Celular"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{ref:"hidden_phone",staticStyle:{"height":"0","width":"0","overflow":"hidden","padding":"0","border":"0"},model:{value:(_vm.formated_phone),callback:function ($$v) {_vm.formated_phone=$$v},expression:"formated_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"xs":"12","md":"12","sm":"12","lg":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Cuéntanos sobre tu contenido"}},[_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":"Escribir mensaje...","rows":"3","required":true,"max-rows":"4"},model:{value:(_vm.formData.question_text),callback:function ($$v) {_vm.$set(_vm.formData, "question_text", $$v)},expression:"formData.question_text"}})],1)],1),_c('b-col',[_c('hr'),_c('br'),_c('b-button',{attrs:{"variant":"primary","block":"","disabled":_vm.clicked || invalid || _vm.errorPhone,"type":"submit"}},[_vm._v(" Guardar Información ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }